import React, {useEffect} from "react";
import QRCode from "react-qr-code";
import {min} from "../Utils/Utils";
import {useSessionInfo} from "../App/SessionInfoProvider";

type QrCodeProps = {
    message: string,
    sessionToken: string,
    qrValue: string,
    receiveTime: number
    lifetimeSeconds: number,
    appName: string
    qrUrl: string | null
}

enum ImageMode {
    QR_CODE = 'qr_code',
    IMAGE = 'image'
}

export const QrCodeView = ({message, sessionToken, qrValue, receiveTime, lifetimeSeconds, appName, qrUrl}: QrCodeProps) => {
    const sessionInfo = useSessionInfo()
    const [timeLeft, setTimeLeft] = React.useState(lifetimeSeconds);
    const [imageMode, setImageMode] = React.useState(ImageMode.QR_CODE)

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            setTimeLeft(lifetimeSeconds - (now - receiveTime) / 1000);
        }, 1000)
        return () => {
            setTimeLeft(lifetimeSeconds);
            clearInterval(interval);
        }
    }, [receiveTime, lifetimeSeconds, qrValue])
    const onCantSeeImage = () => {
        if (imageMode == ImageMode.IMAGE) {
            alert('Sorry to hear that. Please, try to reload page')
        } else {
            setImageMode(ImageMode.IMAGE)
        }
    }

    const qrCodeBlurry = timeLeft < 1
    const percentage = min(100, timeLeft > 0 && lifetimeSeconds > 0 ? (timeLeft / lifetimeSeconds) * 100 : 0);
    const qrSize = (window.innerWidth * 0.8) < 400 ? (window.innerWidth * 0.8) : 400;

    return (
        <>
            <div className="qrState">{message}</div>
            <div className="qrCodeView">
                <div className="qrCode">
                    <div className={qrCodeBlurry ? "qrImage blurry" : "qrImage"}>
                        {imageMode === ImageMode.QR_CODE || qrUrl === null || qrUrl === undefined ?
                            <QRCode size={qrSize} value={qrValue} />
                        : <img src={qrUrl} alt={'Qr code'} width={qrSize} height={qrSize} />}
                        <div className="progressbar">
                            {!qrCodeBlurry && <span style={{width: `${percentage.toFixed(0)}%`, backgroundColor: sessionInfo?.colors?.accent}}></span>}
                        </div>
                        {qrUrl !== null && qrUrl !== undefined ? <div className='cantSeeImage' onClick={onCantSeeImage} style={{ color: sessionInfo?.colors?.primary }}>
                            Can't see image
                        </div> : null}
                    </div>
                </div>
            </div>
        </>
    )
}