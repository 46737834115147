import React, {useEffect} from 'react';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {useParams} from "react-router-dom";
import './App.css';
import {CurrentEnvironment} from "../Core/Env";
import {ResponseWrapper} from "../Core/ResponseWrapper";
import {ContentView} from "../Content/ContentView";
import {AppState} from "./AppState";
import {AppEvent} from "./AppEvent";
import {useSessionInfo} from "./SessionInfoProvider";

const appStateReducer = (state: AppState, event: AppEvent): AppState => {
    const params = state.params
    if (event.type === 'SocketReadyState') {
        const readyState = event.state;
        if (state.type === 'SocketState' || state.type === 'Connecting') {
            if (readyState === ReadyState.CONNECTING) {
                return {params, type: 'Connecting', message: 'Connecting to server'}
            } else if (readyState === ReadyState.OPEN) {
                return {params, type: 'WaitSession', message: 'Waiting for session...'}
            } else if (readyState === ReadyState.CLOSING) {
                return {params, type: 'SocketState', message: 'Connection failed, reconnecting...', blinking: true}
            } else if (readyState === ReadyState.UNINSTANTIATED) {
                return {params, type: 'SocketState', message: 'Connection failed'}
            }
        } else if (state.type === 'SessionRetry') {
            if (readyState === ReadyState.OPEN) {
                return {params, type: 'WaitSession', message: 'Waiting for session...'}
            } else if (readyState === ReadyState.UNINSTANTIATED) {
                return {params, type: 'SocketState', message: 'Connection failed'}
            }
        } else if (state.type !== 'Extracted' && state.type !== 'SessionInvalid') {
            if (readyState === ReadyState.CLOSING) {
                return {params, type: 'SocketState', message: 'Disconnected, reconnecting...', blinking: true}
            } else if (readyState === ReadyState.CLOSED) {
                return {params, type: 'SocketState', message: 'Disconnected, reconnecting...', blinking: true}
            }
        }
    } else if (event.type === 'ResponseWrapper') {
        const responseWrapper = event.wrapper;
        if (responseWrapper.type === 'SessionQrCodeResponse') {
            if (state.type === 'WaitQR') {
                return {
                    type: 'QRIsReady',
                    message: 'QR code is ready!',
                    sessionToken: state.sessionToken,
                    qrValue: responseWrapper.data.qrCode,
                    lifetimeSeconds: responseWrapper.data.lifetimeSeconds,
                    receiveTime: new Date().getTime(),
                    qrUrl: responseWrapper.data.qrImageUrl ?? null,
                    params,
                }
            } else if (state.type === 'QRIsReady') {
                return {
                    type: 'QRIsReady',
                    message: 'QR code is ready!',
                    sessionToken: state.sessionToken,
                    qrValue: responseWrapper.data.qrCode,
                    lifetimeSeconds: responseWrapper.data.lifetimeSeconds,
                    receiveTime: new Date().getTime(),
                    qrUrl: responseWrapper.data.qrImageUrl ?? null,
                    params,
                }
            }
        } else if (responseWrapper.type === 'SessionTokenResponse') {
            if (state.type === 'WaitSession') {
                return {
                    type: 'WaitQR',
                    message: 'Waiting for QR code...',
                    sessionToken: responseWrapper.data.sessionToken,
                    params: { ...params, ...responseWrapper.data },
                }
            }
        } else if (responseWrapper.type === 'LoggedInResponse') {
            if (state.type === 'QRIsReady' || state.type === 'WaitQR') {
                return {params, type: 'LoggedIn', message: 'Authorization successfull. Please, wait a bit'}
            }
        } else if (responseWrapper.type === 'ExtractedNumbersResponse') {
            if (state.type === 'QRIsReady' || state.type === 'WaitQR' || state.type === 'LoggedIn' || state.type === 'Extracted') {
                return {
                    type: 'Extracted',
                    message: `Success! We're authorized to track ${responseWrapper.data.phones.length} phones!`,
                    phones: responseWrapper.data.phones,
                    params,
                };
            }
        } else if (responseWrapper.type === 'SessionInvalidResponse') {
            return {
                type: 'SessionInvalid',
                message: responseWrapper.data.message,
                params,
            };
        } else if (responseWrapper.type === 'SessionRetryResponse') {
            return {
                type: 'SessionRetry',
                message: responseWrapper.data.message,
                params,
            };
        } else if (responseWrapper.type === 'SessionUpdateResponse') {
            return {
                ...state,
                params: responseWrapper.data
            }
        } else {
            console.warn(`unknown message type ${event.wrapper.type}`)
        }
    } else {
        console.warn(`unknown event type: ${event.type}`)
    }
    console.warn(`ignore app event: ${event.type} state:${state.type}`)
    return state
}

export const App = () => {
    const sessionInfo = useSessionInfo()
    const {shortKey} = useParams()
    const [appName, setAppName] = React.useState(sessionInfo?.displayName ?? 'Tracker')
    const [appState, dispatchEvent] = React.useReducer(appStateReducer, {
        type: 'SocketState',
        message: 'Awaiting for initialization',
        params: { keepCompanion: false }
    })

    const {
        lastJsonMessage,
        readyState,
    } = useWebSocket(`${CurrentEnvironment.wsBaseUrl}${shortKey ?? ""}`, {
        protocols: [ "wwl_proto_3", "wwl_proto_2", "wwl_proto_1" ],
        retryOnError: true,
        onOpen: (e) => {
            console.warn(`socket connected ${e.timeStamp} on short link ${shortKey}`)
        },
        shouldReconnect: (e) => {
            const ret = appState.type !== 'Extracted' && appState.type !== 'SessionInvalid'
            console.warn(`socket reconnect:${ret} event:${e.reason} state:${appState.type}`)
            return ret
        },
        onError: (e) => {
            console.error(`socket error: ${e}`)
        },
        onClose: (e) => {
            console.warn(`socket closed: ${e.reason}`)
        },
        onReconnectStop: (attempts: number) => {
            console.error(`connection failed after ${attempts} attempts. reload page state`)
            dispatchEvent({
                type: 'State',
                state: {
                    message: 'We could not establish connection with Server. Please, reload page to try again',
                    type: 'SocketState',
                    params: { keepCompanion: false }
                }
            })
        }
    });

    useEffect(() => {
        console.warn(`socket state received: ${readyState} currentState: ${appState.type}`)
        dispatchEvent({
            type: 'SocketReadyState',
            state: readyState
        })
    }, [readyState])

    useEffect(() => {
        if (lastJsonMessage !== null) {
            const responseWrapper = lastJsonMessage as ResponseWrapper;
            console.warn(`message received type: ${responseWrapper.type} currentState:${appState.type}`)
            dispatchEvent({
                type: 'ResponseWrapper',
                wrapper: responseWrapper
            })
            if (responseWrapper.type === 'SessionTokenResponse') {
                if (responseWrapper.data.appName !== null && responseWrapper.data.appName !== undefined) {
                    setAppName(responseWrapper.data.appName)
                }
            }
        }
    }, [lastJsonMessage])

    return (
        <div className="appRoot" style={{ background: sessionInfo?.colors?.secondary, color: sessionInfo?.colors?.colorOnSecondary }}>
            <div className="appHeader" style={{ background: sessionInfo?.colors?.primary,  color: sessionInfo?.colors?.colorOnPrimary }}>
                <div className="appTitle">
                    {appName} Web Link
                </div>
            </div>
            <div className="appContent">
                <ContentView appName={appName} appState={appState} />
            </div>
            <div className="appFooter" style={{ background: sessionInfo?.colors?.secondary,  color: sessionInfo?.colors?.colorOnSecondary }}>
                <div className="appVersion">{appName} Web Link v{CurrentEnvironment.version}</div>
                <div className="appLinks">
                    {sessionInfo?.privacyPolicyLink && <a style={{ color: sessionInfo?.colors?.colorOnSecondary }} href={sessionInfo.privacyPolicyLink} className="appPrivacyPolicy appLink">Privacy Policy</a>}
                    {sessionInfo?.termsOfUseLink && <a style={{ color: sessionInfo?.colors?.colorOnSecondary }} href={sessionInfo.termsOfUseLink} className="appTos appLink">Terms Of Use</a>}
                    {sessionInfo?.applicationLink && <a style={{ color: sessionInfo?.colors?.colorOnSecondary }} href={sessionInfo.applicationLink} className="appStore appLink">Our Application</a>}
                </div>
            </div>
        </div>
    )
};
