import {SessionInfoResponse} from "./Model";
import {CurrentEnvironment} from "../Env";

export class SessionApi {

    baseUrl: string


    constructor() {
        this.baseUrl = CurrentEnvironment.apiBaseUrl
    }

    buildUrl(endpoint: string): URL {
        return new URL(endpoint, this.baseUrl);
    }

    static async processJsonResponse<T>(response: Response): Promise<T> {
        switch (response.status) {
            case 200: {
                const json = await response.json();
                return json as T
            }
            case 404: {
                throw new Error("Resource cannot be found")
            }
            default: {
                throw new Error(`Internal server error ${response.status}`)
            }
        }
    }

    static async processError<T>(error: any): Promise<T> {
        if (error instanceof Error) {
            throw new Error(error.message);
        }
        throw new Error("Unknown client error occurred")
    }

    getSessionInfo(shortCode: string): Promise<SessionInfoResponse> {
        return fetch(this.buildUrl(`session_info/${shortCode}`))
            .then(e => SessionApi.processJsonResponse<SessionInfoResponse>(e))
            .catch(e => SessionApi.processError<SessionInfoResponse>(e))
    }
}