import {AppState} from "./AppState";
import './CompanionView.css'

export const CompanionView = ({ appState, appName }: { appState: AppState, appName: string }) => {
    if (!appState.params.keepCompanion) {
        return null
    }
    return (
        <div className='companion-view-box'>
            <div className='companion-view'>
                <div className="companion-view-title">
                    <span className="icon material-symbols-rounded">bolt</span>
                    <div>Continuous Tracking feature is enabled in {appName}</div>
                </div>
                <div>
                    <div className='companion-view-line'>It will allow us to <b>automatically get authorization</b> when its expires without additional QR code scanning every 20 days</div>
                    <div className='companion-view-line'>In order to use this feature, <b>you must not remove created WhatsApp Web client</b> that will appear in your device list after QR code scan</div>
                    <div className='companion-view-line danger-line'>Once you remove it, continuous tracking will be unavailable!</div>
                </div>
            </div>
        </div>
    )
}