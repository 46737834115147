import {anyTrueToBoolean} from "../Utils/Utils";

export type Environment = {
    version: string,
    basename: string,
    apiBaseUrl: string
    wsBaseUrl: string,
    debug: boolean
}

export const CurrentEnvironment: Environment = {
    version: `${process.env.REACT_APP_VERSION}`,
    basename: `${process.env.REACT_APP_BASENAME}`,
    apiBaseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
    wsBaseUrl: `${process.env.REACT_APP_WS_BASE_URL}`,
    debug: anyTrueToBoolean(`${process.env.REACT_APP_DEBUG}`)
}