import React from "react";
import {AppState} from "../App/AppState";
import {QrCodeView} from "../Qr/QrCodeView";
import {useParams} from "react-router-dom";
import {CompanionView} from "../App/CompanionView";
import {Hint} from "../App/Hint";
import {useSessionInfo} from "../App/SessionInfoProvider";

export const ContentView = ({ appState, appName }: {appState: AppState, appName: string}) => {
    const { shortKey } = useParams()
    const sessionInfo = useSessionInfo()
    switch (appState.type) {
        case "Connecting":
            return (
                <div className="socketState blinking">
                    {appState.message}
                </div>
            )
        case "WaitSession":
            return (
                <>
                    <div className="socketState">Connected</div>
                    <div className="sessionState blinking">{appState.message}</div>
                </>
            )
        case "WaitQR":
            return (
                <>
                    <div className="sessionState">Session {appState.sessionToken}</div>
                    <div className="qrState blinking">{appState.message}</div>
                    <CompanionView appName={appName} appState={appState} />
                </>
            )
        case "QRIsReady":
            return (
                <>
                    <QrCodeView appName={appName} {...appState} />
                    <CompanionView appName={appName} appState={appState} />
                    <Hint appName={appName} sessionInfo={sessionInfo} />
                </>
            )
        case "LoggedIn":
            return (
                <>
                    <div className="qrState blinking">{appState.message}</div>
                    <CompanionView appName={appName} appState={appState} />
                </>
            )
        case "Extracted":
            return (
                <>
                    <div className="qrState">{appState.message}</div>
                    <div className="qrState">You can now remove WhatsApp Web application from the list in WhatsApp</div>
                    <CompanionView appName={appName} appState={appState} />
                </>
            )
        case "SocketState":
            return (
                <div className={appState.blinking ? "socketState blinking" : "socketState"}>
                    {appState.message}
                </div>
            )
        case "SessionRetry":
            return (
                <>
                    <div className="socketState">{appState.message}</div>
                    <div className="sessionState blinking">Retry in few seconds, please wait...</div>
                </>
            )
        case "SessionInvalid":
            return (
                <div className="sessionError">
                    <div className="hint">
                        <div className="hintTitle titleCenter">This link is invalid</div>
                        <div className="hintTitle">What might went wrong?</div>
                        <div className="hintItem">
                            <span className="icon material-symbols-rounded">text_fields</span>
                            <span className="iconCaption">You could mistype short link from application</span>
                        </div>
                        <div className="hintItem">
                            <span className="icon material-symbols-rounded">nest_clock_farsight_analog</span>
                            <span className="iconCaption">You followed old link that was generated few hours ago. Make sure that you're following newly generated link from application</span>
                        </div>
                        <div className="hintItem">
                            <span className="icon material-symbols-rounded">cloud_off</span>
                            <span className="iconCaption">That could be our fault and our tech team are working on the problem.</span>
                        </div>
                        <div className="hintTitle">What can I do?</div>
                        <div className="hintItem">
                            <span className="icon material-symbols-rounded">edit</span>
                            <span className="iconCaption">Check your URL validity and characters after it. Current URL key is: <code className="codeBig">{shortKey}</code></span>
                        </div>
                        <div className="hintItem">
                            <span className="icon material-symbols-rounded">refresh</span>
                            <span className="iconCaption">Generate a new link in application and try again</span>
                        </div>
                        <div className="hintItem">
                            <span className="icon material-symbols-rounded">qr_code_scanner</span>
                            <span className="iconCaption">Scan QR code directly from application</span>
                        </div>
                        <div className="hintItem">
                            <span className="icon material-symbols-rounded">support_agent</span>
                            <span className="iconCaption">
                                <div>Contact our Support Team if you issuing some problems or have a question</div>
                                <div>
                                    <span className="icon material-symbols-rounded">email</span>
                                    <a href="mailto:support@watracker.io">support@watracker.io</a>
                                </div>
                                <div>
                                    <span className="icon material-symbols-rounded">send</span>
                                    <a href="https://t.me/watracker_support">Telegram</a>
                                </div>
                                <div>
                                    <span className="icon material-symbols-rounded">chat_bubble</span>
                                    <a href="https://wa.me/639953316647">WhatsApp</a>
                                </div>
                            </span>

                        </div>
                    </div>
                </div>
            )
        default:
            return <>Unknown state: {appState['type'] ?? "N/A"}</>
    }
}
