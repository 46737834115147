import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App/App';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {CurrentEnvironment} from "./Core/Env";
import { Home } from './Home';
import {SessionInfoProvider} from "./App/SessionInfoProvider";

const router = createBrowserRouter([
  {
    path: "/:shortKey",
    element: <SessionInfoProvider><App /></SessionInfoProvider>,
  },
  {
    path: "*",
    element: <Home />
  }
], {
  basename: CurrentEnvironment.basename
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
   <RouterProvider router={router} />
  </React.StrictMode>
);
