import {useParams} from "react-router-dom";
import React, {ReactElement, useContext, useEffect, useReducer, useState} from "react";
import {SessionInfoResponse} from "../Core/Api/Model";
import {SessionApi} from "../Core/Api/SessionApi";

export const SessionInfoContext = React.createContext<SessionInfoResponse | null>(null)

export const useSessionInfo: () => SessionInfoResponse | null = () => useContext(SessionInfoContext)

export interface SessionInfoProviderProps {
    children: ReactElement
}

interface SessionInfoState {
    response: SessionInfoResponse | null
    error: Error | null
    isLoading: boolean
}

type SessionInfoEvent = {
    type: 'LOADING',
    value: boolean
} | {
    type: 'DATA',
    value: SessionInfoResponse
} | {
    type: 'ERROR',
    value: Error
}

const initialState: SessionInfoState = {
    response: null,
    error: null,
    isLoading: true
}

export const SessionInfoProvider = (props: SessionInfoProviderProps) => {
    const {shortKey} = useParams()
    const [state, dispatchState] = useReducer((state: SessionInfoState, action: SessionInfoEvent) => {
        switch (action.type) {
            case "LOADING": {
                return { ...state, isLoading: action.value }
            }
            case "DATA": {
                return { ...state, isLoading: false, response: action.value, error: null }
            }
            case "ERROR": {
                return { ...state, isLoading: false, response: null, error: action.value }
            }
        }
        return state
    }, initialState)
    useEffect(() => {
        if (shortKey === null || shortKey === undefined || shortKey.length === 0) {
            dispatchState({ type: 'LOADING', value: false })
            return
        }
        if (state.isLoading) {
            const api = new SessionApi()
            api.getSessionInfo(shortKey)
                .then(e => dispatchState({type: 'DATA', value: e}))
                .catch(e => dispatchState({type: 'ERROR', value: e}))
        }
    }, [])
    if (state.isLoading) {
        return (<div></div>)
    }
    return (
        <SessionInfoContext.Provider value={state.response}>
            {props.children}
        </SessionInfoContext.Provider>
    )
}