import React from "react";
import {SessionInfoResponse} from "../Core/Api/Model";

export const Hint = ({ sessionInfo, appName }: { appName: string, sessionInfo: SessionInfoResponse | null }) => {
    return (
        <div className="hint-box">
            <div className="hint" style={{ background: sessionInfo?.colors?.accent,  color: sessionInfo?.colors?.colorOnAccent }}>
                <div className="hintTitle">How does it work?</div>
                <div className="hintItem">
                    <span className="icon material-symbols-rounded">qr_code_scanner</span>
                    <span className="iconCaption">You need to scan this QR code with WhatsApp or WhatsApp Business application on iOS or Android.</span>
                </div>
                <div className="hintItem">
                    <span className="icon material-symbols-rounded">key</span>
                    <span className="iconCaption">By doing this you're authorizing {appName} as WhatsApp Web client so We can gather authorization to track your contacts.</span>
                </div>
                <div className="hintItem">
                    <span className="icon material-symbols-rounded">privacy_tip</span>
                    <span className="iconCaption">We're <b>not accessing nor storing</b> your messages and contact list.</span>
                </div>
                <div className="hintItem">
                    <span className="icon material-symbols-rounded">lock</span>
                    <span className="iconCaption">After operation complete we're not be able to log in into your account any further. You surely can delete the Web application from your WhatsApp account.</span>
                </div>
                <div className="hintItem">
                    <span className="icon material-symbols-rounded">phone_iphone</span>
                    <span className="iconCaption">You will <b>not be logged out from WhatsApp application.</b> Your account will stay intact during and after this operation.</span>
                </div>
            </div>
        </div>
    )
}